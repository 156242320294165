initSlider();

function initSlider() {
	var slides = document.querySelectorAll('#header-slider > div');
	var counters = document.querySelectorAll('#header-slider-counter > div');
	var textSlides = document.querySelectorAll('#header-slider-txt > div');

	var currentSlide = 0;

	slides[currentSlide].classList.add('active');
	counters[currentSlide].classList.add('active');
	textSlides[currentSlide].classList.add('active');

	setInterval(function () {
		slides[currentSlide].classList.remove('active');
		counters[currentSlide].classList.remove('active');
		textSlides[currentSlide].classList.remove('active');

		currentSlide = currentSlide < slides.length - 1 ? currentSlide + 1 : 0;

		slides[currentSlide].classList.add('active');
		counters[currentSlide].classList.add('active');
		textSlides[currentSlide].classList.add('active');
	}, 7500);
}
